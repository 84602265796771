<template>
  <div class="politicas-container">
    <Menu2></Menu2>
    <Menu></Menu>
    <div class="container-ghost">
        <div class="content-politics">
            <div class="tittle-politics">
                <h4>Términos y Condiciones</h4>
                <p>(Fecha de Última Actualización: 09 de Agosto de 2021)</p>
            </div>
            <div class="politics-paragraph">
                <h6>1. Introducción</h6>
                <p>El presente documento establece los términos y condiciones generales del uso (“Términos y Condiciones Generales”) que serán aplicables cuando usted visite y/o utilice la Plataforma Digital y/o el Sitio Web <a href="https://www.alteraacademy.com/">https://www.alteraacademy.com</a> (en adelante, de manera conjunta “Plataforma”), cuyas titularidades y contenido son de total autoría de Sociedad de Educación Altera SPA. (“ALTERA ACADEMY”), sociedad constituida en Ñuñoa, Región Metropolitana de Santiago, Chile, con Rut 77.228.791-7, domiciliada en Armando Carrera 5218, Ñuñoa, Santiago, Chile.</p>
                <p>A continuación, se muestra la información legal aplicable a ALTERA ACADEMY. </p>
                <p>Es necesario también leer las Políticas de Privacidad, en donde detallamos nuestras prácticas de manejo de información <a href="https://www.alteraacademy.com/">https://www.alteraacademy.com/politicas</a>. Nos preocupamos por mantener seguros a nuestros usuarios y estamos en constante trabajo para recopilar la información de manera confidencial. El acceso y/o utilización del Sitio Web atribuye al internauta la condición de usuario del Sitio Web (el “Usuario” o los “Usuarios”), lo cual implica la plena aceptación de todas las disposiciones incluidas en el presente documento.</p>
            </div>
            <div class="politics-paragraph">
                <h6>2. Definiciones</h6>
                <p>Sin perjuicio de los términos definidos en diversas secciones del presente documento, los términos aquí utilizados con la primera letra mayúscula y que no sean definidos de forma diversa, tendrán el significado que a continuación se les atribuye, pudiendo ser utilizados en masculino, femenino o neutro, así como en singular o en plural, indistintamente y según el contexto:</p>
                <p>“Cookie” es el archivo de datos que se almacena en el dispositivo del Usuario cuando éste tiene acceso al Sitio.</p>
                <p>“Cuenta del Usuario” es la forma de identificación creada (que consta de un ID de acceso y contraseña) que se le da a los Usuarios para que puedan acceder a los Servicios que ofrece ALTERA ACADEMY a través de la Plataforma. (En adelante, la “Cuenta” o “Cuenta del Usuario”).</p>
                <p>“Curso” es aquel espacio curricular en el cual los Mentores y/o Profesores se encargarán de impartir conocimientos respecto de una determinada materia y/o tema en particular a un número de Usuarios 1 mediante clases dictadas a través de la Plataforma de ALTERA ACADEMY.</p>
                <p>“Leyes Vigentes” significan todas las leyes, códigos, normas jurídicas, ordenanzas, reglamentos, tratados, reglas, normas, permisos, resoluciones, órdenes, interpretaciones, decisiones, interdictos, así como las normas, resoluciones y sentencias que actualmente o en el futuro estén vigentes en Chile y resulten aplicables a los Usuarios.</p>
                <p>“Mentores” es aquella persona especializada en determinadas disciplinas, materias, entre otros integradas relacionados a los Cursos, que compartirán su experiencia y conocimientos con los Usuarios, a fin de poder guiarlos o absolver las dudas que los Usuarios tengan en los Cursos.</p>
                <p>“Plataforma” se refiere al sitio web, plataforma virtual, y otros medios digitales como aplicaciones para dispositivos smartphones con tecnología IOS o Android, tablets, SmartTv, entre otros, cuyas titularidades corresponden a ALTERA ACADEMY, y que dentro de los cuales los Usuarios se registran para obtener el Servicio.</p>
                <p>“Producto” es el término que define el acceso al Curso o a un paquete de Cursos, dentro del catálogo de Cursos ofrecido por ALTERA ACADEMY. En caso el Usuario elija acceder a uno de los Productos, este podrá acceder al Curso y visualización de clases en línea y/o en vivo, y sus respectivos contenidos.</p>
                <p>“Profesor” es aquel profesional o especialista en una determinada materia o ámbito de conocimiento, disciplina académica, ciencia o arte que, enseñará un Curso o varios Cursos de manera virtual, a través de la Plataforma.</p>
                <p>“Servicio” es el servicio brindado por ALTERA ACADEMY el cual consiste en poner a disposición de sus Usuarios, Cursos sobre diversas temáticas o ámbitos de conocimiento, disciplina académica, ciencia o arte, los cuales son dictados por Profesores y/o Mentores especializados en la materia. El servicio brindado a través de la Plataforma podrá estar conformado de Cursos en línea y/o en vivo, ayuda personalizada en los Cursos a través de Mentores, eventos y video conferencias, entre otros, que ALTERA ACADEMY comunique en su debido momento.</p>
                <p>“Usuario” es aquella persona natural que se registra en la Plataforma con la finalidad de contratar el Servicio y/o los Servicios expuestos por ALTERA ACADEMY. Asimismo, podrán ser Usuarios de la Plataforma aquellas partes interesadas en formar parte de la experiencia brindada a través de nuestros Servicios tales como los Profesores, afiliados y miembros del equipo de ALTERA ACADEMY.</p>
                <p>“Términos y Condiciones particulares” serán los términos y condiciones propios de cada curso.</p>
            </div>
            <div class="politics-paragraph">
                <h6>3. Descripción del Sitio</h6>
                <p>La Plataforma es una comunidad educativa para personas que quieren aprender habilidades creativas de profesionales en el mercado. Dentro de ALTERA ACADEMY, los Usuarios podrán inscribirse a Cursos online, compartir proyectos personales, contribuir y aprender en foros de discusión, interactuar con personas que guarden mismos intereses y leer o comentar en artículos o tutoriales. En la Plataforma también podrán publicar imágenes, fotografías, archivos de audio y video, compartir promociones, descuentos, si así lo desean y previa autorización por parte del Usuario.</p>
                <p>A través de la Plataforma, los Usuarios se inscriben a Cursos, a través de los cuales pueden tener acceso a contenido audiovisual, foros de discusión y contacto directo con el Profesor de la clase. Estas Condiciones, las Políticas de Privacidad y otros avisos legales publicados por ALTERA ACADEMY son ajenos a los acuerdos, comunicados y otros documentos (“Compromisos de Clase”) que el alumno y Profesor puedan fijar dentro de la clase. ALTERA ACADEMY no se hará responsable por ninguna obligación incluida dentro de los Compromisos de Clase o por cualquier acción tomada en base a los mismos, además de no considerarse parte de ninguna de las transacciones entre el alumno y el Profesor. ALTERA ACADEMY se reserva el derecho de eliminar la oferta de cualquier clase y/o Curso, en cualquier momento y por cualquier motivo.</p>
            </div>
            <div class="politics-paragraph">
                <h6>4. Aceptación de las condiciones</h6>
                <p>ALTERA ACADEMY ha puesto a su disposición la Plataforma, por lo que, al acceder a ella, usted debe de leer, comprender y aceptar estos términos y condiciones (las “Condiciones”) Asimismo, queda sujeto a estas Condiciones que, junto con nuestras Políticas de Privacidad, rigen nuestra relación con usted dentro de la Plataforma. Solo podrá registrarse y ser Usuario de la Plataforma, después de haber aceptado las Condiciones, en caso de no aceptarlos, se le solicita que no haga uso, ni acceda, ni manipule la información de los Servicios ofrecidos por la Plataforma, ya que usted estaría haciendo un uso inadecuado de ésta.</p>
            </div>
            <div class="politics-paragraph">
                <h6>5. Disponibilidad de los Contenidos</h6>
                <p>Los contenidos mostrados a través de la Plataforma podrán incluir todo tipo de archivos, que podrán ser propios o de terceros, tales como textos, imágenes, sonidos y videos, enlaces (links) a otros sitios o aplicaciones ajenos a ALTERA ACADEMY, y cualquier otro contenido que ALTERA ACADEMY considere pertinente agregar.</p>
                <p>ALTERA ACADEMY no garantiza que los contenidos que se ofrecen en la Plataforma estén disponibles siempre, por lo que ALTERA ACADEMY se reserva el derecho de suspender o adicionar cualquier funcionalidad en la Plataforma.</p>
            </div>
            <div class="politics-paragraph">
                <h6>6. Acceso y utilización del Sitio Web</h6>
                <p>Los Usuarios podrán disfrutar de todas las funcionalidades y Servicios que ofrece la Plataforma, desde que se registran en la Cuenta.</p>
                <p>El acceso y el uso de la Plataforma sólo podrá ser realizado por aquellas personas que conforme a la legislación aplicable tengan capacidad de ejercicio plena para celebrar actos jurídicos. En caso que, algún menor de edad, quiera acceder a los Servicios que ofrezca la Plataforma, los que ejerzan la patria potestad o tutores de los menores de edad o de aquellas personas reputadas por las Leyes Vigentes como sujetos de derecho con incapacidad absoluta serán responsables por los actos realizados por estos últimos.</p>
            </div>
            <div class="politics-paragraph">
                <h6>7. Registro</h6>
                <p>Los Usuarios deberán registrarse en la Plataforma para crearse una Cuenta, debiendo completar el formulario de registro con toda su información personal tales como nombres completos, un nombre de usuario, colocar un correo electrónico, entre otros. Asimismo, deberá elegir una contraseña.</p>
                <p>Solo se podrá registrar una cuenta por Usuario. El Usuario deberá de proporcionar información certera, completa y actualizada. No hacerlo representa una violación de los presentes Términos y Condiciones Generales y podría significar la cancelación de la cuenta, si en caso ALTERA ACADEMY lo considere conveniente.</p>
                <p>El nombre de Usuario elegido al momento del registro no deberá atentar contra las buenas costumbres, vulnerar los derechos de terceros, provocar equívocos, confundir a otros usuarios ni atentar contra la reputación o imagen de ALTERA ACADEMY, reservándose el derecho de modificar cualquier nombre de usuario que considere inapropiado sin previo aviso.</p>
            </div>
            <div class="politics-paragraph">
                <h6>8. Responsabilidad por contraseñas</h6>
                <p>Es responsabilidad del Usuario cuidar sus datos de ingreso y de cualquier actividad dentro de su cuenta, por lo que deberá mantenerla bajo absoluta reserva y confidencialidad, quedando prohibida la acción de compartirla con terceros. Toda acción realizada a través de la Cuenta del Usuario se presume realizada por este y no se admite prueba en contrario.</p>
                <p>ALTERA ACADEMY no se hace responsable de los daños y perjuicios que puedan suceder por actos de seguridad ni de los daños relacionados con la divulgación del nombre del Usuario o de su contraseña. Si existe algún tipo de violación de seguridad o identifica que alguien ha accedido a su cuenta, el Usuario debe de notificarlo instantáneamente a ALTERA ACADEMY.</p>
                <p>En caso de olvido de la contraseña o de cualquier otra circunstancia que suponga un riesgo de acceso y/o de utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente a ALTERA ACADEMY, a fin de proceder inmediatamente con el bloqueo y/o sustitución de la misma. En todo caso, todas las operaciones o transacciones realizadas con anterioridad a dicha comunicación se entenderán efectuadas por el Usuario, quien, de ser el caso, será responsable y pagará los gastos y los daños y perjuicios que se deriven de cualquier acceso o uso no autorizado a la Plataforma.</p>
            </div>
            <div class="politics-paragraph">
                <h6>9. Uso correcto de la Plataforma</h6>
                <p>La Plataforma podrá ser utilizada únicamente con fines lícitos y que no transgredan las buenas costumbres y/o el presente documento.</p>
                <p>El Usuario se compromete a utilizar la Plataforma con fines lícitos y que no transgredan el ordenamiento jurídico, el orden público y las buenas costumbres. En ese sentido, a título meramente enunciativo, pero no limitativo, el Usuario se abstendrá, entre otros, de:</p>
                <p>Abusar, acosar, amenazar, suplantar o intimidar a cualquier persona.</p> 
                <p>Publicar o transmitir cualquier tipo de contenido difamatorio, obsceno, pornográfico, abusivo, ofensivo, profano o que infrinja derecho de autor o derechos en general de cualquier persona.</p>
                <p>Intentar acceder o realizar búsquedas en motores de búsquedas o softwares (spiders, robots, crawlers, herramientas de minería de datos o similares) distintos de aquellos provistos por ALTERA ACADEMY o aquellos motores de búsquedas genéricos provistos por terceros y de acceso público la Internet.</p>
                <p>Generar o enviar correos no deseados a cualquier persona o URL.</p>
                <p>Crear cuentas múltiples con el objetivo de votar a favor o en contra de los proyectos de otros usuarios.</p>
                <p>Publicar contenidos de los cuales no posea los derechos de autor.</p>
                <p>Aumentar o alterar el número de votos o comentarios de forma artificial, o bien de otro Servicio, con el fin de dar o recibir dinero o cualquier tipo de compensación por dichos votos.</p>
                <p>Vender o transferir su Cuenta de Usuario.</p>
                <p>Utilizar la Plataforma para fines comerciales o para el beneficio de terceros de manera que infrinja lo establecido en el presente documento.</p>
                <p>Manipular cualquier paquete TCP/IP, encabezado (header) o cualquier parte de la información de encabezado en cualquier correo electrónico, newsgroup, newsletter o utilizar de cualquier manera la Plataforma para enviar información falsa, alterada o que no permita identificar la fuente original desde donde se envió.</p>
                <p>Intentar descifrar, descompilar o aplicar ingeniería en reversa a cualquier programa de software proveedor del Sitio Web, entre otros.</p>
                <p>Asimismo, ALTERA ACADEMY ha establecido los siguientes lineamientos generales para el correcto uso de los Servicios, por lo que el Usuario deberá acatarlas y alentar a otros los Usuarios a hacer lo mismo:</p>
                <p>El Usuario podrá hacer uso de los Servicios de ALTERA ACADEMY, bajo conformidad con las Leyes Vigentes.</p>
                <p>El Usuario podrá acceder de manera ilimitada a la Plataforma y descargar los contenidos dentro del plazo de vigencia determinado del curso.</p>
                <p>El Usuario no podrá descargar, grabar, archivar, reproducir, distribuir, modificar, mostrar, ejecutar, publicar, otorgar licencias, crear obras derivadas basadas en el contenido de titularidad de ALTERA ACADEMY u obtenida a través de su Plataforma, con fines lucrativos.</p>
                <p>El Usuario tendrá permitido guardar el acceso a su Cuenta en más de un dispositivo (smartphones, tablets, SmartTv). Sin embargo, no podrá acceder a su Cuenta de manera simultánea en más de un dispositivo.</p>
                <p>El Usuario podrá compartir contenidos de manera pública en la Plataforma, por lo que, no deberá realizar acciones, comentarios o compartir archivos que demuestren acoso, referencias sexuales, abuso, bullying, racismo, spam, u otra acción que atente contra las normas y las buenas costumbres, o que afecten a la comunidad de ALTERA ACADEMY.</p>
                <p>ALTERA ACADEMY se reserva el derecho de retirar, terminar o suspender, sin previo aviso y en cualquier momento la Cuenta a aquellos Usuarios que infrinjan los presentes Términos y Condiciones Generales, por lo que, por favor, le pedimos que no use ALTERA ACADEMY para ningún fin ilícito y/o negativo.</p>
            </div>
            <div class="politics-paragraph">
                <h6>10. Contenido compartido en el Sitio Web</h6>
                <p>El Usuario conservará todos los derechos y propiedad intelectual del contenido que comparta en ALTERA ACADEMY. Sin embargo, al subir o compartir cualquier tipo de contenido a la Plataforma, le concede a ALTERA ACADEMY (y afiliados o socios comerciales y de comunicación) una licencia global, no exclusiva, para comunicar, distribuir, alojar, modificar o derivar trabajos, presentar, publicar, reproducir, almacenar o utilizar el contenido. Si incluimos el contenido compartido a través de cualquier medio de comunicación de ALTERA ACADEMY, le daremos todos los créditos merecedores.</p>
                <p>Además, al compartir el contenido, le concede a ALTERA ACADEMY y a sus Usuarios y visitantes el derecho de compartirlo a través de varias plataformas de redes sociales integradas a la Plataforma (Facebook, Twitter, Pinterest, Instagram o Linkedin). ALTERA ACADEMY no asegura ni garantiza que el contenido que se publique en la Plataforma no resulte copiado de forma ilegal y sin su consentimiento.</p>
                <p>ALTERA ACADEMY tampoco les prohíbe a los Usuarios y visitantes de la Plataforma que hagan copias en baja resolución o miniaturas de su trabajo, por lo que autoriza que ALTERA ACADEMY permita a los usuarios y visitantes de la Plataforma hacer dichas copias de su contenido en baja resolución.</p>
            </div>
            <div class="politics-paragraph">
                <h6>11. Productos que ofrece la Plataforma</h6>
                <p>ALTERA ACADEMY pone a disposición de sus Usuarios Productos.</p>
                <p>A los Productos que ALTERA ACADEMY exponga a través de la Plataforma, los que podrán incluir Cursos, un paquete de Cursos y/o Certificaciones, seleccionados conforme a sus necesidades e intereses. El Usuario podrá acceder a los Productos realizando un pago único a través de la Plataforma, y tendrá acceso a sus contenidos de manera ilimitada durante la existencia de la Plataforma.</p>
            </div>
            <div class="politics-paragraph">
                <h6>12. Cursos y certificaciones</h6>
                <p>ALTERA ACADEMY mantiene sus Cursos y Especializaciones a la vanguardia y deseamos que nuestros Usuarios tengan una experiencia completa a través de la Plataforma. En ese sentido, ALTERA ACADEMY podrá otorgar diplomas a los Usuarios siempre y cuando haya finalizado el Curso o el conjunto de Cursos de una Especialización de manera satisfactoria.</p>
                <p>Los Cursos estarán integrados por un determinado número de clases virtuales grabadas, así como de ayuda personalizada en los Cursos a través de mentores, eventos y video conferencias, entre otros que ALTERA ACADEMY comunique en su debido momento. En ese sentido, el Usuario deberá asistir a un mínimo de clases por Curso.</p>
                <p>Por otro lado, la evaluación del desenvolvimiento y aprendizaje de los Usuarios estará a cargo de los Profesores del Curso y/o de los Mentores, por lo que, éstos asignarán a cada Usuario inscrito, la elaboración de un proyecto que desarrolle los contenidos del Curso, a fin de que el Usuario obtenga el diploma correspondiente. Cabe mencionar que, ALTERA ACADEMY se reserva el derecho de publicar en su Plataforma los mencionados proyectos, según su calificación y criterio. Al estar disponibles en la Plataforma podrán ser sometidos a votación y comentarios de los Usuarios, independientemente del Curso y/o Especialización.</p>
            </div>
            <div class="politics-paragraph">
                <h6>13. Formas de Pago</h6>
                <p>ALTERA ACADEMY se reserva el derecho de contratar a terceros que actúen como facilitadores para gestionar los pagos de los cursos que se realicen a través de la Plataforma. Asimismo, ALTERA ACADEMY procederá primero a verificar que el pago de los cursos haya sido debidamente efectuado para proceder con la activación de la cuenta.</p>
                <p>Si el pago no se pudiera efectuar satisfactoriamente, debido a la fecha de vencimiento, la falta de fondos u otros motivos, y si usted no cancela su cuenta, podemos suspender el acceso a los Cursos hasta que obtengamos una Forma de pago válida.</p>
            </div>
            <div class="politics-paragraph">
                <h6>14. Sobre la relación con los Profesores</h6>
                <p>ALTERA ACADEMY no mantiene una relación laboral ni de dependencia y/o subordinación con alguno de los Profesores. ALTERA ACADEMY únicamente es el productor del diseño de la temática de los Cursos y del contenido de la Plataforma, sin embargo, los Profesores tendrán libertad de cátedra, por lo que, ALTERA ACADEMY le otorga la confianza a sus profesores para exponer sus conocimientos e información sin sujetarse a una doctrina impuesta por ALTERA ACADEMY.</p>
                <p>El Profesor es un tercero que a través de clases expone un Curso y/o Cursos a través de la Plataforma, por lo que el Usuario reconoce que, ALTERA ACADEMY únicamente será responsable de validar que, el Profesor comparta el contenido adecuado y apropiado en los Cursos que enseñará a través de la Plataforma.</p>
                <p>En ese sentido, ALTERA ACADEMY no ofrece garantía alguna ni gestiona o interviene en la relación Profesor – Usuario, por lo que, en caso el Usuario tenga algún inconveniente con el contenido del Curso impartido por el Profesor o cualquier queja respecto del mismo, deberá comunicarlo a ALTERA ACADEMY, a través del siguiente correo electrónico contacto@alteraacademy.com, a fin de que ALTERA ACADEMY pueda tomar las medidas pertinentes.</p>
                <p>En el supuesto de que, ALTERA ACADEMY detecte que el contenido compartido por el Profesor no cumpla con los estándares apropiados, ALTERA ACADEMY se encontrará facultado para restringir dicho contenido. Sin embargo, ALTERA ACADEMY no será responsable de la veracidad o exactitud de la información proporcionada por el Profesor a través del Curso.</p>
            </div>
            <div class="politics-paragraph">
                <h6>15. Sobre la relación con los Profesores</h6>
                <p>ALTERA ACADEMY no controla ni se hace responsable por el acceso por parte de los Usuarios a contenidos, materiales, productos o servicios a través de enlaces de terceros colocados en el Sitio Web. El acceso a cualquier contenido incluyendo los enlaces mostrados en la Plataforma son opcionales, y correrán bajo su propio riesgo y cuenta, liberando a ALTERA ACADEMY de cualquier responsabilidad por dicha conducta.</p>
                <p>Por lo anterior y toda vez que, ALTERA ACADEMY no tiene control sobre los contenidos de sus proveedores y/o terceros, incluidos los sitios o aplicaciones a que lleven los enlaces, ALTERA ACADEMY no será responsable por los contenidos, materiales, acciones y/o servicios prestados por sus proveedores y/o terceros, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente.</p>
            </div>
            <div class="politics-paragraph">
                <h6>16. Promociones, ofertas, lanzamientos comerciales</h6>
                <p>En cualquier momento, ALTERA ACADEMY podrá lanzar ofertas promocionales, Cursos gratuitos, inclusive becas para alumnos nuevos, entre otros, por lo que, éstos se regirán bajo las condiciones y restricciones adicionales que se establezcan al momento de su lanzamiento, los cuales serán publicados en la página web de ALTERA ACADEMY.</p>
            </div>
            <div class="politics-paragraph">
                <h6>17. Propiedad intelectual</h6>
                <p>El software asociado a la Plataforma, así como todos los contenidos disponibles en el mismo, son propiedad exclusiva de ALTERA ACADEMY, y/o de ser el caso, de los Profesores, de sus proveedores o de sus servidores externos; por tanto, están protegidos por las leyes y los tratados internacionales en materia de derechos de autor, marcas, patentes, modelos y diseños industriales aplicables en Chile, sin importar que se encuentren o no registrados.</p>
                <p>Se encuentra terminantemente prohibido cualquier tipo de copia, distribución, transmisión, retransmisión, publicación, impresión, difusión y/o explotación comercial del material y/o contenido puesto a disposición del público a través de la Plataforma, sin el previo consentimiento expreso y por escrito de ALTERA ACADEMY o, en su caso, del titular de los derechos de propiedad intelectual correspondientes. El incumplimiento de lo aquí mencionado sujetará al infractor a todos los reclamos civiles y será materia para la presentación de la querella, la denuncia o la reclamación procedente ante las autoridades competentes, dentro de la cual se proporcionará la información necesaria para la investigación y persecución del ilícito del que se trate.</p>
            </div>
            <div class="politics-paragraph">
                <h6>18. Derechos de Autor</h6>
                <p>Todos los derechos de autor involucrados en la Plataforma, como por ejemplo su contenido y su diseño visual, son de titularidad de ALTERA ACADEMY y/o de sus proveedores, por lo que se encuentra estrictamente prohibido su empleo, modificación, reproducción, distribución, transmisión o comercialización de los derechos involucrados sin el permiso previo, expreso y por escrito de los titulares de dichos derechos, salvo los casos de derechos de cita, empleo para finalidades educativas y los demás usos honrados reconocidos.</p>
            </div>
            <div class="politics-paragraph">
                <h6>19. Privacidad</h6>
                <p>De conformidad con la Ley de Protección de Datos Personales, aprobada por Ley No. 19628, ALTERA ACADEMY implementará todas las medidas posibles para mantener la confidencialidad y la seguridad de la información y datos de los Usuarios, pero no responderá por los daños, pérdidas o perjuicios que pudieran derivarse de la violación de esas medidas por parte de terceros que utilicen las redes públicas o internet, alterando los sistemas de seguridad para obtener acceso a la información de los Usuarios.</p>
                <p>Sin perjuicio de lo anterior, el uso de la Plataforma se encuentra condicionado a la lectura y aceptación libre por parte del Usuario de la Política de Privacidad, la cual se encuentra a su disposición en la Plataforma, por lo que, en caso de registrarse como Usuario en ALTERA ACADEMY, el Usuario manifiesta su conformidad con las Políticas de Privacidad.</p>
                <p>Altera Academy realiza alianzas con otras instituciones, razón por la cual en virtud de dichas alianzas y con las autorizaciones pertinentes, se reserva el derecho de volver a contactar a las personas que hayan participado como usuarios finales (beneficiarios) en alguna de estas alianzas.</p>
                <p>En caso de que te hayamos contactado de esta manera y no desees recibir nuestra información, puedes solicitarnos que te retiremos de nuestra base de datos a través de contacto@alteraacademy.com</p>
            </div>
            <div class="politics-paragraph">
                <h6>20. Cookies</h6>
                <p>El Usuario conviene en recibir las Cookies que les transmitan los servidores de ALTERA ACADEMY. Las Cookies pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado. Por ello, es importante que lea y acepte la Política de Cookies que se encuentra a disposición en la Plataforma, a fin de poder registrarse como Usuario y acceder a los Servicios.</p>
            </div>
            <div class="politics-paragraph">
                <h6>21. Exclusión de garantías</h6>
                <p>Respecto de la información y contenidos. - ALTERA ACADEMY pone a disposición del Usuario un espacio virtual en el que podrá acceder a los Cursos dictados por los Profesores, por lo que, ALTERA ACADEMY no ofrece garantía alguna, ni gestiona o interviene en la relación, a los Servicios o información que puedan surgir entre los Usuarios.</p>
                <p>Respecto de errores o virus en la Plataforma. - ALTERA ACADEMY no es responsable de los posibles daños o perjuicios en la Plataforma, que puedan derivar de interferencias, omisiones, interrupciones, virus informáticos, averías o desconexiones en el funcionamiento operativo del sistema electrónico, motivado por causas ajenas a ALTERA ACADEMY de retrasos o bloqueos en el uso del sistema causados por deficiencias o sobrecargas en Internet o en otros sistemas electrónicos, así como también de daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas fuera del control de ALTERA ACADEMY.</p>
                <p>Respecto de los Servicios. - ALTERA ACADEMY, sus empleados o directivos no son responsables de los daños o perjuicios indirectos, accidentales o por eventos de caso fortuito, incluyendo daños por lucro cesante, pérdida de uso y pérdida de datos derivados de o en relación con los Cursos, los Proyectos y/o los presentes términos.</p>
            </div>
            <div class="politics-paragraph">
                <h6>22. Disponibilidad y Suspensión del Servicio</h6>
                <p>En caso de incumplimiento de cualquiera de las disposiciones contenidas en los presentes Términos y Condiciones, ALTERA ACADEMY podrá suspender la Cuenta, suspender el Servicio y/o retirarle o denegarle el acceso a la Plataforma o al Sitio Web, en cualquier momento y sin necesidad de notificación previa de ningún tipo, así como, de ser el caso, presentar las denuncias ante las autoridades competentes. Se considerará infracción a los presentes Términos y Condiciones, la comisión o participación en actos que constituyan conductas abusivas, inapropiadas o ilícitas, actividades fraudulentas o ilegales y en general el comercio de cualquier objeto ilícito o producto de un delito.</p>
            </div>
            <div class="politics-paragraph">
                <h6>23. Cambios en las Condiciones y Cesión de Posición contractual</h6>
                <p>ALTERA ACADEMY se reserva el derecho de actualizar, modificar o reemplazar cualquier parte de estas Condiciones, sin necesidad de previo aviso. Todos los Términos y Condiciones Generales modificados entrarán en vigor al momento de su publicación para todos los efectos legales a que haya lugar.</p>
                <p>Es la responsabilidad del Usuario revisarlo de manera periódica para poder identificar cambios. Su navegación en la Plataforma supondrá la aceptación de cualquier modificación realizado en las Condiciones. Además, ALTERA ACADEMY podría incluir nuevos Servicios o funciones en el futuro. De ser el caso, tales Servicios se verán sujetos a los Condiciones especificados en este documento. En caso de no estar de acuerdo con las modificaciones que se pudiesen dar, el Usuario podrá cancelar su suscripción y/o presentar sus reclamos, dudas y/o consultas al siguiente correo electrónico contacto@alteraacademy.com.</p>
                <p>Asimismo, ALTERA ACADEMY podrá ceder o transferir los derechos y obligaciones como su posición contractual derivados del presente documento y/o de la Plataforma, en cualquier momento. Por lo que, el Usuario autoriza expresamente la cesión del presente documento, así como la Política de Privacidad en favor de cualquier persona que (i) quede obligada por el presente; y/o (ii) que sea el nuevo responsable de los derechos y/o obligaciones que surjan del presente documento y/o de la Plataforma. Luego de producida la cesión, ALTERA ACADEMY no tendrá ninguna responsabilidad con respecto de cualquier hecho que ocurra, a partir de la fecha de la cesión.</p>
            </div>
            <div class="politics-paragraph">
                <h6>24. Indemnización</h6>
                <p>El Usuario mantendrá indemne a ALTERA ACADEMY, sus filiales, empresas controladas y/o controlantes, directivos, administradores, representantes y empleados, respecto de cualquier reclamo o demanda de otros Usuarios o terceros por las actividades que el Usuario pueda realizar en la Plataforma, y/o por cualquier incumplimiento a las Condiciones y demás Políticas que se entienden incorporadas al presente o por la violación de cualquier ley o derechos de terceros.</p>
            </div>
            <div class="politics-paragraph">
                <h6>25. Jurisdicción y legislación aplicable</h6>
                <p>Todas las controversias que deriven de la interpretación o el cumplimiento de los presentes Términos y Condiciones Generales o que guarden relación con éstos, incluyendo cualquier reclamo que pudiera una de las Partes tener en contra de la otra derivada de, o relacionado con el uso de la Plataforma se regirá por las leyes chilenas, renunciando en este acto las Partes a cualquier otra legislación o jurisdicción que, por motivo de sus domicilios presentes o futuros, pudiere corresponderles y se someten a los Juzgados y Tribunales de Chile.</p>
                <p>Para sugerencias y/o comentarios sobre los Términos y Condiciones Generales de nuestra Plataforma pueden enviarnos una comunicación al siguiente correo electrónico contacto@alteraacademy.com.</p>
                <p>Declaro que luego de haber leído los Términos y Condiciones Generales, acepto todo lo anteriormente mencionado. En señal de conformidad, procedo a llenar el formulario de compra de usuario visitante.</p>
            </div>
        </div>
    </div>
    <Footer @search-course="footerSearch($event)"></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'politicas',
  components: {
    Menu,
    Menu2,
    Footer,
  },
  data() {
    return {
    }
  },

};
</script>

<style scoped>
.politicas-container{
  font-family: "Poppins";
}
.container-ghost{
    padding: 8rem 2rem 5rem 2rem;
    background-color: #f2f2f2;;
}
.content-politics{
    background-color: #fff;
    padding: 2rem;
}
.tittle-politics{
  text-align: left;
  color:#525bb7;
}
.tittle-politics p{
  font-size: 14px;
}
.politics-paragraph{
 text-align: justify;
 padding-top: 1rem;
}
.politics-paragraph h6{
  font-weight: bold;
  font-size: 14px
}
.politics-paragraph p{
  font-size: 14px
}
@media (min-width: 992px){
    .content-politics{
        padding: 3rem;
    }
}

</style>